<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea v">
			<div class="inner">
				<h2 class="txtL v3">프로젝트 신규 등록</h2>
				<a @click="moveBack()" class="btnBack">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<!-- inputList -->
			<div class="inputList">
				<dl>
					<dt>프로젝트 명</dt>
					<dd>
						<input
							type="text"
							v-model="name"
							title="프로젝트 명"
							placeholder="프로젝트 명"
							maxlength="254"
							required="required"
						/>
					</dd>
				</dl>
				<dl>
					<dt>시작일자</dt>
					<dd>
						<DatePicker
							v-model="schedule.beginDate"
							valueType="format"
							format="YYYY-MM-DD"
							:confirm="true"
							:editable="false"
							:clearable="false"
							placeholder="날짜선택"
							confirm-text="확인"
							@change="handlePicker()"
						/>
					</dd>
				</dl>
				<dl>
					<dt>종료일자</dt>
					<dd>
						<DatePicker
							v-model="schedule.endDate"
							valueType="format"
							format="YYYY-MM-DD"
							:confirm="true"
							:editable="false"
							:clearable="false"
							placeholder="날짜선택"
							:disabled-date="disableEndDate"
							confirm-text="확인"
						/>
					</dd>
				</dl>
			</div>
			<!--// inputList -->
			<!-- inner -->
			<div class="inner">
				<!-- btnBottom -->
				<div class="btnBottom">
					<button type="button" class="btn" @click="onMove()">등록</button>
				</div>
				<!--// btnBottom -->
			</div>
			<!--// contents -->
		</div>
		<!--// inner -->
	</div>

	<!--// container -->
</template>

<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ko';
import project from '@/api/project';
import building from '@/api/building';
import store from '@/store';

import { showSpinner, hideSpinner, hideMenu } from '@/utils/cmm.js';

export default {
	components: {
		DatePicker: DatePicker,
	},
	created() {},
	mounted() {
		this.buildingId = this.$route.params.buildingId;
		hideMenu();
		if (!this.buildingId) {
			this.$router.push({ name: 'buildingList' });
		}
		this.reload();
	},
	beforeDestroy() {},
	data() {
		return {
			name: '',
			schedule: {
				name: null,
				beginDate: this.$moment().format('YYYY-MM-DD'),
				endDate: this.$moment(this.beginDate)
					.add(1, 'd')
					.format('YYYY-MM-DD'),
				intervalType: 'Y',
			},
			buildingId: null,
		};
	},
	methods: {
		// 시작일 데이터픽커 핸들링
		handlePicker() {
			this.$moment(this.schedule.beginDate).format('YYYY-MM-DD');

			// 종료날짜와 시작날짜가 같거나 빠르면 , 종료날짜 + 1 수정
			if (this.schedule.beginDate > this.schedule.endDate || this.schedule.beginDate == this.schedule.endDate) {
				this.schedule.endDate = this.$moment(this.schedule.beginDate)
					.add(1, 'd')
					.format('YYYY-MM-DD');
			}
		},

		// 종료일 데이트픽커 disabled days 설정
		disableEndDate(date) {
			let followingDay = this.$moment(this.schedule.beginDate)
				.add(0, 'day')
				.format('YYYY-MM-DD');
			return date < new Date(followingDay);
		},
		moveBack() {
			this.$router.push({ name: 'projectList', params: { buildingId: this.buildingId } });
		},
		async onMove() {
			let params = {
				buildingId: this.buildingId,
				name: this.name,
				beginDate: this.schedule.beginDate,
				endDate: this.schedule.endDate,
			};
			try {
				showSpinner();
				await project.create(params);
				this.$alert(`프로젝트가 등록되었습니다.`);
				this.moveBack();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async reload() {
			try {
				showSpinner();
				let res = await building.getOne(this.buildingId);

				// 빌딩 생성자가 아닌경우 (공유자 접근제한) 예외 발생시키기
				// 빌딩 소유자?등록자? 정보 가져오기
				if (res.owner != store.state.auth.id) {
					throw {
						message: '빌딩 생성자만 접근 가능합니다.',
					};
				}
			} catch (ex) {
				await this.$alert(`건물 상세조회 에러가 발생했습니다.\r\n(${ex.message})`);
				return this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
	},
};
</script>
